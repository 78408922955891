<script setup>
const props = defineProps({
  showModal: {
    type: Boolean,
    default: false,
  },
  appearance: {
    type: String,
    default: 'warning',
  },
  title: {
    type: String,
    default: '',
  },
  message: {
    type: String,
    default: '',
  },
  denyButtonText: {
    type: String,
  },
  confirmButtonText: {
    type: String,
  },
  denyButtonAppearance: {
    type: String,
    default: 'secondary',
  },
  confirmButtonAppearance: {
    type: String,
    default: 'primary',
  },
})

const emit = defineEmits(['confirm', 'deny'])
const disabledButton = ref(false)

watch(
  () => props.showModal,
  () => {
    disabledButton.value = false
  },
)

function handleClick(action) {
  disabledButton.value = true
  emit(action)
}
</script>

<template>
  <KModal :show-modal="showModal" style="width: 418px; padding: 4px 8px">
    <template #title>
      <h1 class="text-xl mb-3 mt-2 font-semibold tracking-[0.02px]">
        {{ title }}
      </h1>
    </template>
    <template #message>
      <p class="text-sm">
        {{ message }}
      </p>
      <slot />
    </template>
    <template #icon>
      <IconsWarningCircle v-if="appearance === 'warning'" color="dialog-box-warning" class="mt-1" size="35" />
      <IconsWarningCircle v-if="appearance === 'error'" color="dialog-box-error" class="mt-1" size="35" />
      <IconsErrorCircle v-if="appearance === 'danger'" color="dialog-box-error" class="mt-1" size="35" />
      <IconsCheckmarkCircle v-if="appearance === 'success'" class="mt-1" size="35" />
      <IconsWarningCircle v-if="appearance === 'info'" class="mt-1" color="dialog-box-info" size="35" />
    </template>
    <template #buttons>
      <div class="flex w-full gap-2">
        <KButton
          v-if="denyButtonText"
          :class="{ 'w-1/2': confirmButtonText, 'w-full': !confirmButtonText, 'danger bg-danger': denyButtonAppearance === 'danger' }" :appearance="denyButtonAppearance" :disabled="disabledButton" @click="handleClick('deny')"
        >
          {{ denyButtonText }}
        </KButton>
        <slot name="btnConfirm">
          <KButton
            v-if="confirmButtonText"
            :class="{ 'w-1/2': denyButtonText, 'w-full': !denyButtonText, 'danger bg-danger': confirmButtonAppearance === 'danger' }" :appearance="confirmButtonAppearance" :disabled="disabledButton" @click.once="handleClick('confirm')"
          >
            {{ confirmButtonText }}
          </KButton>
        </slot>
      </div>
    </template>
  </KModal>
</template>

<style scoped>
.dialog-box-warning {
  @apply text-[#FF991F]
}

.dialog-box-error {
  @apply text-red-600
}

.dialog-box-info {
  @apply text-blue-600
}

.danger{
  @apply text-white
}

.bg-danger{
  @apply bg-[#B22A09]
}

.bg-danger:hover{
  @apply bg-[#D81600]
}
</style>
